import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/extended/Button';
import { navigate } from "gatsby";

import {
  Row,
  Col,
  Input,
  FormField,
  KineduLink,
  ButtonsContainer,
  AreYouAParentText,
  AlreadyAMemberText,
  LoginCTAFormContainer,
} from './styles';
import { randomString } from '../../../lib/utils';
import LoginModal from 'components/Landing/modals/Login/Login';
import withLocation from '../../withLocation';
import { useTranslation } from 'react-i18next';

const inlineStyles = { textAlign: 'right' };

const LoginCTAForm = ({
  hideLinkToKinedu = false,
  className,
  search,
  doNotTrack = false,
}) => {
  const id = randomString();
  const { t } = useTranslation();
  const [loginModal, setLoginModal] = useState(false);
  const [email, setEmail] = useState(search.forFreeTrial || '');

  const handleOnClickLogin = () => {
    window.location?.assign(window.location.href.replace(window.origin, `${window.origin}/app`))
    // navigate('?');
    // setTimeout(() => {
    //   setLoginModal(true);
    // }, 50);
  };

  const handleOnSubmit = (e) => {
    navigate('/signup');
    // navigate(`?forFreeTrial=${email}`);
    // setLoginModal(true);
    e.preventDefault();
  };

  const handleOnChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  useEffect(() => {
    if (search.forFreeTrial && !doNotTrack) {
      setLoginModal(true);
    }
  }, []);

  return (
    <LoginCTAFormContainer className={className} onSubmit={handleOnSubmit}>
      <Row>
        <Col>
          <FormField>
            {/* <label htmlFor={`input-email-${id}`}>
              {t('AUTH_VIEW.YOUR_EMAIL')}
            </label>
            <Input
              type="email"
              value={email}
              onChange={handleOnChangeEmail}
              id={`input-email-${id}`}
              placeholder={t('AUTH_VIEW.EMAIL_PLACEHOLDER')}
              required
            /> */}
          </FormField>
        </Col>
        <Col>
          <ButtonsContainer>
            <Button interval={6000} type="submit" shadowColored color="secondary">
              {t('START_YOUR_FREE_TRIAL')}
            </Button>
            <AlreadyAMemberText hideXLPhone>
              {t('AUTH_VIEW.ALREADY_HAVE_ACCOUNT')}
            </AlreadyAMemberText>
          </ButtonsContainer>
        </Col>
      </Row>
      <Row>
        <Col>
          <AlreadyAMemberText hide showXLPhone style={inlineStyles}>
            {t('AUTH_VIEW.ALREADY_HAVE_ACCOUNT')}
          </AlreadyAMemberText>
        </Col>
        <Col>
          <ButtonsContainer>
            <Button
              className="LogIn"
              onClick={handleOnClickLogin}
              uppercased
              shadowColored
              color="primary"
            >
              {t('LOGIN')}
            </Button>
            {!hideLinkToKinedu && (
              <AreYouAParentText>
                {t('AUTH_VIEW.ARE_YOU_PARENT')}
                &nbsp;
                <KineduLink>
                  {t('AUTH_VIEW.GO_TO_KINEDU')}
                </KineduLink>
              </AreYouAParentText>
            )}
          </ButtonsContainer>
        </Col>
      </Row>
      {
        loginModal && (
          <LoginModal onClose={() => setLoginModal(false)} />
        )
      }
    </LoginCTAFormContainer>
  );
};

LoginCTAForm.propTypes = {
  hideLinkToKinedu: PropTypes.bool,
  search: PropTypes.object,
  doNotTrack: PropTypes.bool,
};

LoginCTAForm.defaultProps = {
  hideLinkToKinedu: false,
  search: {},
  doNotTrack: false,
};

export default withLocation(LoginCTAForm);

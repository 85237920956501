import React from 'react';
import styled from 'styled-components';
import ListItem from 'components/Landing/ListItem/ListItem';

import stepOne from 'images/landing/step_1.svg';
import stepTwo from 'images/landing/step_2.svg';
import stepThree from 'images/landing/step_3.svg';
import stepFour from 'images/landing/step_4.svg';
import { useTranslation } from 'react-i18next';

const GetStartedSectionComponent = styled.section`
  background-color: #fff;
  color: white;
  padding: 8rem 0;
`;

const GetStartedContainer = styled.div`
  max-width: 1060px;
  margin: 0 auto;
  padding: 0 20px;
`;

const GetStartedTitle = styled.h2`
  color: #333333;
  font-size: 2rem;
  font-weight: bold;
  line-height: 30px;
  padding: 0px 0;
  text-align: center;
  margin: 25px 0;
  text-transform: uppercase;
  ${({ theme }) => `
    ${theme.breakPoints.largeTablet} {
      font-size: 3.6rem;
    }
  `}
`;

const GetStartedSubtitle = styled.h3`
  color: ${({ theme, color = 'blue' }) => theme.colors[color]};
  text-transform: uppercase;
  font-size: 1.6rem;
  letter-spacing: 4px;
  font-weight: 500;
  line-height: 30px;
  padding: 0px 0;
  text-align: center;
  margin: 10px 0;
  ${({ theme }) => `
    ${theme.breakPoints.largeTablet} {
      font-size: 3.6rem;
    }
  `}
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  ${({ theme, alignCenter, justifyCenter }) => `
    ${justifyCenter ? 'justify-content: center' : ''}
    ${alignCenter ? 'align-items: center' : ''}
    ${theme.breakPoints.xLargePhone} {
      flex-direction: row;
    }
  `}
`;

const Col = styled.div`
  width: 100%;
  ${({ theme }) => `
    ${theme.breakPoints.xLargePhone} {
      flex-direction: row;
      width: 33.3%;
    }
  `}
`;

const GetStartedCardContainer = styled.div`
  padding: 10px;
  .ListItemContainer {
    text-align: center;
    img {
      max-width: 130px;
    }
    p {
      font-family: 'Proxima Nova';
      color: #8B8B8B;
      font-weight: 300;
      font-size: 1.6rem;
      strong {
        font-weight: 500;
        color: #000;
        display: block;
      }
    }
    ${({ theme }) => `
      ${theme.breakPoints.largeTablet} {
        p {
          font-size: 2rem;
        }
        img {
          max-width: 200px;
        }
      }
    `}
  }
`;

const GetStartedSection = ({
  innerRef,
}) => {
  const { t } = useTranslation();

  return (
    <GetStartedSectionComponent ref={innerRef}>
      <GetStartedContainer>
        <GetStartedTitle>
          {t('GET_STARTED_SECTION.TITLE')}
        </GetStartedTitle>
        <GetStartedSubtitle color="blue">
          {t('GET_STARTED_SECTION.TEACHERS.TITLE')}
        </GetStartedSubtitle>
        <Row justifyCenter>
          <Col>
            <GetStartedCardContainer>
              <ListItem
                className="animate__zoomIn"
                delayAnimation="0.5s"
                image={stepOne}
                description={t('GET_STARTED_SECTION.TEACHERS.STEP_ONE')}
              />
            </GetStartedCardContainer>
          </Col>
        </Row>
        <GetStartedSubtitle color="green">
          {t('GET_STARTED_SECTION.PARENTS.TITLE')}
        </GetStartedSubtitle>
        <Row>
          <Col>
            <GetStartedCardContainer>
              <ListItem
                className="animate__zoomIn"
                delayAnimation="1s"
                image={stepTwo}
                description={t('GET_STARTED_SECTION.PARENTS.STEP_TWO')}
              />
            </GetStartedCardContainer>
          </Col>
          <Col>
            <GetStartedCardContainer>
              <ListItem
                className="animate__zoomIn"
                delayAnimation="1.5s"
                image={stepThree}
                description={t('GET_STARTED_SECTION.PARENTS.STEP_THREE')}
              />
            </GetStartedCardContainer>
          </Col>
          <Col>
            <GetStartedCardContainer>
              <ListItem
                className="animate__zoomIn"
                delayAnimation="2s"
                image={stepFour}
                description={t('GET_STARTED_SECTION.PARENTS.STEP_FOUR')}
              />
            </GetStartedCardContainer>
          </Col>
        </Row>
      </GetStartedContainer>
    </GetStartedSectionComponent>
  );
};

export default GetStartedSection;

import React from 'react';
import styled from 'styled-components';

const MissionSectionComponent = styled.section`
  background-color: ${({ theme }) => theme.colors.indigo};
  color: white;
  font-size: 2rem;
  font-style: italic;
  font-weight: lighter;
  line-height: 30px;
  padding: 20px;
`;

const MissionContainer = styled.div`
  max-width: 1280px;
  margin: 0 auto;
`;

const MissionSectionText = styled.p`
  color: white;
  font-size: 2.2rem;
  font-style: italic;
  font-weight: 300;
  line-height: 38px;
  padding: 20px 10px;
  margin: 0;
  text-align: left;
  ${({ theme }) => `

${theme.breakPoints.desktop} {
      text-align: center;
      font-size: 2.4rem;
      line-height: 4.2rem;
      padding: 20px 10px;
    }
  `}
`;


const MissionSection = ({
  children,
  innerRef,
}) => (
  <MissionSectionComponent ref={innerRef}>
    <MissionContainer>
      <MissionSectionText>{children}</MissionSectionText>
    </MissionContainer>
  </MissionSectionComponent>
);

export default MissionSection;

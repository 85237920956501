import React from 'react';
import styled from 'styled-components';
import TestimonialCard from 'components/Landing/TestimonialCard/TestimonialCard';
import loadable from '@loadable/component';
import { useTranslation } from 'react-i18next';

const TinySlider = loadable(() => import('tiny-slider-react'));

const TestimonialsSectionComponent = styled.section`
  background-color: ${({ theme }) => theme.colors.blueLight};
  color: white;
  padding: 7rem 0;
`;

const TestimonialsContainer = styled.div`
  max-width: 1267px;
  margin: 0 auto;
  padding: 0 20px;
`;

const TestimonialsTitle = styled.h2`
  color: #333;
  font-size: 1.375rem;
  font-weight: bold;
  line-height: 30px;
  padding: 20px 0;
  text-align: center;
  margin: 10px 0;
  text-transform: uppercase;
  letter-spacing: 5px;
  ${({ theme }) => `
    ${theme.breakPoints.xLargePhone} {
      font-size: 3.6rem;
    }
  `}
`;

const settings = {
  lazyload: false,
  nav: true,
  mouseDrag: true,
  controls: false,
  navPosition: 'bottom',
  responsive: {
    640: {
      items: 2,
      nav: false,
    },
    900: {
      items: 4,
      nav: false,
    },
  },
};

const cardStyles = { margin: '10px' };

const TestimonialsSection = ({
  innerRef,
}) => {
  const { t } = useTranslation();

  return (
    <TestimonialsSectionComponent ref={innerRef}>
      <TestimonialsContainer>
        <TestimonialsTitle> {t('USE_CASES_TITLE')} </TestimonialsTitle>
        <TinySlider settings={settings}>
          <div>
            <TestimonialCard
              style={cardStyles}
              animationDelay="0.5s"
              title={t('USE_CASES.ONE.TITLE')}
              className="animate__backInUp"
              description={t('USE_CASES.ONE.DESCRIPTION')}
              />
          </div>
          <div>
            <TestimonialCard
              className="animate__backInUp"
              animationDelay="0.5s"
              style={cardStyles}
              title={t('USE_CASES.TWO.TITLE')}
              description={t('USE_CASES.TWO.DESCRIPTION')}
            />
          </div>
          <div>
            <TestimonialCard
              style={cardStyles}
              className="animate__backInUp"
              animationDelay="0.5s"
              title={t('USE_CASES.THREE.TITLE')}
              description={t('USE_CASES.THREE.DESCRIPTION')}
            />
          </div>
          <div>
            <TestimonialCard
              style={cardStyles}
              className="animate__backInUp"
              animationDelay="0.5s"
              title={t('USE_CASES.FOUR.TITLE')}
              description={t('USE_CASES.FOUR.DESCRIPTION')}
            />
          </div>
        </TinySlider>
      </TestimonialsContainer>
    </TestimonialsSectionComponent>
  );
};

export default TestimonialsSection;

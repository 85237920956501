import React from 'react';
import styled from 'styled-components';
import myVillageImage from 'images/Landing/my_village.svg';
import semperAltiusImage from 'images/Landing/semper_altius.svg';
import advenioImage from 'images/Landing/advenio.svg';
import { useTranslation } from 'react-i18next';

const Rectangle = styled.div`
  transition: all 0.5s ease-in;
  top: -2rem;
  position: absolute;
  left: 0;
  width: 44px;
  height: 122px;
  ${({ theme }) => `
    background-color: ${theme?.colors?.primary};
    ${theme.breakPoints.tablet} {
      width: 327px;
      height: 52px;
      left: 8rem;
    }
  `}
`;

const Circle = styled.div`
  height: calc(75px * 2);
  width: 75px;
  border-bottom-left-radius: calc(75px * 2);
  border-top-left-radius: calc(75px * 2);
  top: -8rem;
  right: 0rem;
  background-color: ${({ theme }) => theme?.colors?.green};

  ${({ theme }) => `

    ${theme.breakPoints.tablet} {
      right: 8rem;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      border-radius: 50%;
      width: 159px;
      height: 159px;
    }
  `}

  transition: all 0.5s ease-in;
  position: absolute;
`;

const PartnersSectionComponent = styled.section`
  background-color: #F4F4F4;
  color: white;
  padding: 0;
`;

const PartnersContainer = styled.div`
  max-width: 1060px;
  position: relative;
  margin: 0 auto;
  padding: 0 20px;
  padding: 5rem 0;
  ${({ theme }) => `
    ${theme.breakPoints.desktop} {
      padding: 5rem 6rem;
    }
  `}
`;

const PartnersTitle = styled.h2`
  color: ${({ theme }) => theme.colors.blue};
  font-size: 2.2rem;
  font-weight: bold;
  line-height: 30px;
  padding: 20px 0;
  text-align: center;
  margin: 10px 0;
  ${({ theme }) => `
    ${theme.breakPoints.tablet} {
      font-size: 3.6rem;
    }
  `}
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  ${({ theme }) => `
    ${theme.breakPoints.xLargePhone} {
      flex-direction: row;
    }
  `}
`;

const Col = styled.div`
  width: 100%;
  ${({ theme }) => `
    width: 100%;
    ${theme.breakPoints.xLargePhone} {
      flex-direction: row;
      width: 33.3%;
    }
  `}
`;

const Image = styled.img.attrs(() => ({
  className: 'Image',
}))`
  width: 100%;
  max-width: 250px;
`;

const PartnerCardContainer = styled.div`
  padding: 10px;
  text-align: center;
  min-height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}`;

const PartnersSection = ({
  innerRef,
}) => {
  const { t } = useTranslation();

  return (
    <PartnersSectionComponent ref={innerRef}>
      <PartnersContainer>
        <Rectangle className="wow animate__animated animate__slower animate__pulse animate__infinite infinite" />
        <Circle className="wow animate__animated animate__slower animate__pulse animate__infinite infinite" />
        <PartnersTitle>
          {t('PARTNERS.TITLE')}
        </PartnersTitle>
        <Row>
          <Col>
            <PartnerCardContainer>
              <Image src={advenioImage} />
            </PartnerCardContainer>
          </Col>
          <Col>
            <PartnerCardContainer>
              <Image src={semperAltiusImage} />
            </PartnerCardContainer>
          </Col>
          <Col>
            <PartnerCardContainer>
              <Image src={myVillageImage} />
            </PartnerCardContainer>
          </Col>
        </Row>
      </PartnersContainer>
    </PartnersSectionComponent>
  );
};

export default PartnersSection;

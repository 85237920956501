import React from 'react';
import styled from 'styled-components';

import HighlightItem from 'components/Landing/HighlightItem/HighlightItem';

import planYourDayImage from 'images/landing/plan_your_day.png';
import planYourDayImageDesktop from 'images/landing/plan_your_day_desktop.png';

import realtimeVirtual from 'images/landing/realtime_virtual.png';
import realtimeVirtualDesktop from 'images/landing/realtime_virtual_desktop.png';

import familyEngagement from 'images/landing/family_engagement.png';
import familyEngagementDesktop from 'images/landing/family_engagement_desktop.png';
import { useTranslation } from 'react-i18next';

const HighlightsSectionComponent = styled.section`
  padding: 20px;
`;

const HighlightsContainer = styled.div`
  max-width: 1280px;
  margin: 0 auto;
`;

const HighlightsSection = ({
  innerRef,
}) => {
  const { t } = useTranslation();

  return (
    <HighlightsSectionComponent ref={innerRef}>
      <HighlightsContainer>
        <HighlightItem
          title={t('PLAN_YOUR_DAY_TITLE')}
          delayAnimationRightSection="0.3s"
          delayAnimationLeftSection="0.6s"
          description={t('PLAN_YOUR_DAY_DESCRIPTION')}
          image={planYourDayImage}
          imageDesktop={planYourDayImageDesktop}
          reversed
        />
        <HighlightItem
          title={t('REALTIME_VIRTUAL_TITLE')}
          delayAnimationRightSection="0.6s"
          delayAnimationLeftSection="0.9s"
          description={t('REALTIME_VIRTUAL_DESCRIPTION')}
          image={realtimeVirtual}
          imageDesktop={realtimeVirtualDesktop}
        />
        <HighlightItem
          reversed
          title={t('MEANINGFUL_FAMILY_TITLE')}
          delayAnimationRightSection="0.9s"
          delayAnimationLeftSection="1.2s"
          description={t('MEANINGFUL_FAMILY_DESCRIPTION')}
          image={familyEngagement}
          imageDesktop={familyEngagementDesktop}
        />
      </HighlightsContainer>
    </HighlightsSectionComponent>
  );
};

export default HighlightsSection;

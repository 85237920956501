import React, { useState, useEffect, useCallback } from 'react';
import { ThemeProvider } from 'styled-components';
import { Modal } from 'kinedu-react-components';
import Layout from 'components/LandingLayout';
import SEO from 'components/seo';
import { Waypoint } from 'react-waypoint';
import { useTranslation } from 'react-i18next';
import 'animate.css/animate.css';

import theme from '../themes';
import { IFRAMED_LANDING, UNBOUNCE_URL, windowGlobal } from '../config';
import { WithTrans } from '../language/withTrans';
import AuthView from '../views/AuthView';
import ForgotPasswordView from '../views/ForgotPasswordView';
import EmailSentView from '../views/EmailSentView';
import metaTranslations from '../lib/utils';
import EventReporter, { getEventProviderInstance } from '../appEvents';
import { AppEvents, EventProviders } from '../constants/events';

import Footer from 'components/Landing/Footer/Footer';
import landingHeroImage from 'images/landing/landing-hero.png';
import NavbarComponent from 'components/Landing/Navbar/Navbar';
import LandingHero from 'components/Landing/LandingHero/LandingHero';
import LoginCTAForm from 'components/Landing/LoginCTAForm/LoginCTAForm';
import MissionSection from 'components/Landing/sections/MissionSection/MissionSection';
import HighlightsSection from 'components/Landing/sections/HightlightsSection/HighlightsSection';
import TestimonialsSection from 'components/Landing/sections/TestimonialsSection/TestimonialsSection';
import LoginCTAFormSection from 'components/Landing/sections/LoginCTAFormSection/LoginCTAFormSection';
import EveryoneBenefitsSection from 'components/Landing/sections/EveryoneBenefitsSection/EveryoneBenefitsSection';
import GetStartedSection from '../components/Landing/sections/GetStartedSection/GetStartedSection';
import PartnersSection from '../components/Landing/sections/PartnersSection/PartnersSection';

const IndexPage = ({ lang, location }) => {
  const pathLang = location.pathname.match(/\/(.+)\//)?.[1];
  const [isAuthModalOpen, setIsAuthModalOpen] = useState('');
  const [isForgotPassModalOpen, setIsForgotPassModalOpen] = useState(false);
  const [emailSent, setEmailSent] = useState('');
  const { t } = useTranslation();
  const TRANSLATED_URL = `${UNBOUNCE_URL}${pathLang || ''}`;
  windowGlobal.localStorage.setItem('LANDING_LOCALE', lang);

  useEffect(() => {
    const ReactPixel = getEventProviderInstance(EventProviders.FB_PIXEL);

    if (ReactPixel) {
      ReactPixel.pageView();
    }

    import('wowjs')
      .then(({ default: library }) => {
        // eslint-disable-next-line no-new
        new library.WOW({
          live: false,
          animateClass: 'animate__animated',
          mobile: false,
        }).init();
      });
  }, []);

  const handleOnEnterSection = useCallback((section) => {
    // setTimeout(() => {
    //   EventReporter.action(AppEvents.LANDING_SCROLL({ section }), [EventProviders.MIXPANEL]);
    // }, 1000);
  }, []);

  const Sections = {
    HERO: 'landing_hero',
    MISSION: 'mission',
    HIGHLIGHTS: 'highlights',
    TESTIMONIALS: 'use_cases',
    CTA_LOGIN_FORM: 'cta_login_form',
    BENEFITS: 'benefits',
    GET_STARTED: 'get_started',
    PARTNERS: 'partners',
    FOOTER: 'footer',
  };

  return (
    <Layout>
      <SEO
        title={metaTranslations[lang].title}
        description={metaTranslations[lang].description}
        meta={[{ name: 'og:image', content: 'https://kinedu-media.s3.amazonaws.com/classrooms_by_kinedu_2.jpg' }]}
      />
      {IFRAMED_LANDING ? <iframe title="unbounce-landing" id="iframe-unbounce" src={TRANSLATED_URL} frameBorder="0" /> : (
        <ThemeProvider theme={theme}>
          <NavbarComponent lang={lang} />
          <Waypoint onEnter={() => handleOnEnterSection(Sections.HERO)}>
            <LandingHero
              image={landingHeroImage}
              title={t('MAIN_TITLE')}
            >
              <LoginCTAForm />
            </LandingHero>
          </Waypoint>
          <Waypoint
            bottomOffset="20%"
            onEnter={() => handleOnEnterSection(Sections.MISSION)}
          >
            <MissionSection>
              {t('MISSION')}
            </MissionSection>
          </Waypoint>
          <Waypoint
            bottomOffset="20%"
            onEnter={() => handleOnEnterSection(Sections.HIGHLIGHTS)}
          >
            <HighlightsSection />
          </Waypoint>
          <Waypoint
            onEnter={() => handleOnEnterSection(Sections.TESTIMONIALS)}
          >
            <TestimonialsSection />
          </Waypoint>
          <Waypoint
            onEnter={() => handleOnEnterSection(Sections.CTA_LOGIN_FORM)}
          >
            <LoginCTAFormSection />
          </Waypoint>
          <Waypoint
            onEnter={() => handleOnEnterSection(Sections.BENEFITS)}
          >
            <EveryoneBenefitsSection />
          </Waypoint>
          <Waypoint
            onEnter={() => handleOnEnterSection(Sections.GET_STARTED)}
          >
            <GetStartedSection />
          </Waypoint>
          <Waypoint
            onEnter={() => handleOnEnterSection(Sections.PARTNERS)}
          >
            <PartnersSection />
          </Waypoint>
          <Waypoint
            onEnter={() => handleOnEnterSection(Sections.FOOTER)}
          >
            <Footer lang={lang} />
          </Waypoint>
          <Modal
            openModal={isAuthModalOpen}
            onClosedModal={() => setIsAuthModalOpen('')}
          >
            {() => (
              <AuthView
                isLogin={isAuthModalOpen === 'login'}
                onForgotPassword={() => {
                  setIsAuthModalOpen('');
                  setIsForgotPassModalOpen(true);
                }}
              />
            )}
          </Modal>
          <Modal
            openModal={isForgotPassModalOpen}
            onClosedModal={() => {
              setIsForgotPassModalOpen(false);
              setEmailSent('');
            }}
          >
            {() =>
              emailSent
                ? <EmailSentView email={emailSent} />
                : <ForgotPasswordView onSuccess={(email) => setEmailSent(email)} />}
          </Modal>
        </ThemeProvider>
      )}
    </Layout>
  );
};

export default WithTrans()(IndexPage);

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const BenefitCard = styled.div.attrs(() => ({
  className: 'BenefitCard',
}))`
  box-shadow: 0 2px 4px 0 rgba(165,165,165,0.5);
  padding: 20px;
  border-radius: 8px;
  transition: box-shadow 0.3s ease-in;
  user-select: none;
  background-color: #FFFFFF;
  &:hover {
    box-shadow: 1px 3px 12px 0px  rgba(0,0,0,0.48);
  }
`;

const BenefitCardImage = styled.img.attrs(() => ({
  className: 'BenefitCardImage',
}))`
  width: 120px;
  height: 120px;
  margin: 0 auto;
  display: block;
`;

const BenefitTitle = styled.h3.attrs(() => ({
  className: 'BenefitTitle',
}))`
  color: #3A3A3A;
  font-weight: 600;
  font-size: 1.8rem;
  text-align: center;
`;

const BenefitDescription = styled.p.attrs(() => ({
  className: 'BenefitDescription',
}))`
  color: #8B8B8B;
  font-weight: 400;
  font-size: 1.3rem;
  margin: 10px 0;
  min-height: 65px;
`;


const BenefitCardComponent = ({
  image,
  title,
  description,
  className,
  style,
  animationDelay = '0.5s',
  animationDuration = '1s',
}) => (
  <BenefitCard style={style} className={`wow ${className}`} data-wow-duration={animationDuration} data-wow-delay={animationDelay}>
    <BenefitCardImage src={image} />
    <BenefitTitle>
      {title}
    </BenefitTitle>
    <BenefitDescription>
      {description}
    </BenefitDescription>
  </BenefitCard>
);

BenefitCardComponent.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  animationDelay: PropTypes.string,
  animationDuration: PropTypes.string,
};

BenefitCardComponent.defaultProps = {
  style: {},
  className: '',
  animationDelay: '0.5s',
  animationDuration: '1s',
};

export default BenefitCardComponent;

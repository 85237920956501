import styled from 'styled-components';

export const Input = styled.input.attrs(() => ({
  className: 'InputComponent',
}))`
  width: 100%;
  height: 50px;
  padding: 10px;
  box-sizing: border-box;
  font-size: 1.5rem;
  border-radius: 4px;
  border: 1px solid #979797;
  transition: border 0.4s ease-in;
  &:focus {
    ${({ theme }) => `
      border: 1px solid ${theme?.colors?.primary};
      outline: none;
    `}
  }

`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => `
    ${theme.breakPoints.xLargePhone} {
      button:not(.LogIn) {
        margin-top: 27px;
      }
    }
  `}
`;

export const Row = styled.div.attrs(() => ({
  className: 'Row',
}))`
  display: flex;
  flex-direction: column;
  ${({ theme }) => `
    ${theme.breakPoints.xLargePhone} {
      flex-direction: row;
      .Col:first-child {
        padding-right: 20px;
        width: 50%;
      }
      .Col:last-child {
        padding-left: 20px;
        width: 50%;
      }
    }
  `}
`;

export const LoginCTAFormContainer = styled.form.attrs(() => ({
  className: 'LoginCTAFormContainer',
}))``;

export const Col = styled.div.attrs(() => ({
  className: 'Col',
}))`
  margin-bottom: 1rem;
`;

export const AlreadyAMemberText = styled.p`
  font-weight: 300;
  padding: 0;
  color: #333333;
  text-align: center;
  margin: 15px 0 0px 0;
  font-style: italic;

  ${({ hide }) => hide ? `
    display: none;
  ` : ''}

  ${({ theme, hideXLPhone, showXLPhone, hideTablet, showTablet }) => `
    ${theme.breakPoints.xLargePhone} {
      ${hideXLPhone ? 'display: none;' : ''}
      ${showXLPhone ? 'display: block;' : ''}
    }

    ${theme.breakPoints.tablet} {
      ${hideTablet ? 'display: none;' : ''}
      ${showTablet ? 'display: block;' : ''}
    }
  `}

`;

export const AreYouAParentText = styled.p`
  font-weight: 400;
  color: #333333;
  text-align: center;
  margin: 15px 0 10px 0;
  a {
    ${({ theme }) => `
      color: ${theme?.colors?.primary};
      font-weight: 600;
    `}
  }
`;

export const FormField = styled.fieldset`
  border: 0;
  padding: 0;
  label {
    font-weight: 500;
    font-size: 1.4rem;
  }
  ${Input} {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export const KineduLink = styled.a.attrs(() => ({
  target: '_blank',
  rel: 'noopener noreferrer',
  href: 'https://kinedu.com',
}))`
  text-decoration: underline;
`;

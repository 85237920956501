import React, { useCallback, useMemo, useState } from 'react';
import { Modal } from 'kinedu-react-components';
import styled from 'styled-components';
import { navigation } from 'gatsby';

import AuthView from 'src/views/AuthView';
import ForgotPasswordView from 'src/views/ForgotPasswordView';
import EmailSentView from 'src/views/EmailSentView';
import withLocation from '../../../withLocation';

const LoginModal = styled(Modal).attrs(() => ({
  className: 'LoginModal',
  openModal: true,
  closeButtonColor: '#D2D2D2',
}))`
  border-radius: 0;
  height: 100%;
  transition: all 0.3s ease-in;
  display: flex;
  align-items: center;
  overflow-y: auto;

  ${({ theme }) => `
    ${theme.breakPoints.xLargePhone} {
      border-radius: 4px;
      max-width: 625px;
      max-height: 80%;
      height: fit-content;

      .AuthView {
        max-width: 407px;
        margin: 0 auto;
      }
    }
  `}
`;

const Views = {
  LOGIN: 'LOGIN',
  FORGOT_PASSWORD: 'forgot_password',
  RESET_PASSWORD: 'reset_password_success',
};

const LoginComponent = ({
  onClose,
  search,
}) => {
  const [persistent, setPersistence] = useState(true);
  const [isLogin, setIsLogin] = useState(true);
  const [resetEmail, setResetEmail] = useState('');
  const [currentView, setCurrentView] = useState(Views.LOGIN);
  const [email, setEmail] = useState(search?.forFreeTrial || '');

  const handleOnSignIn = () => setIsLogin(true);

  const handleOnSignUp = () => setIsLogin(false);

  const handleOnForgotPassword = () => {
    setCurrentView(Views.FORGOT_PASSWORD);
  };

  const handleOnResetPassword = useCallback((email) => {
    setCurrentView(Views.RESET_PASSWORD);
    setResetEmail(email);
    setPersistence(false);
  }, []);

  useMemo(() => {
    if (search?.forFreeTrial) {
      setIsLogin(false);
      setEmail(search.forFreeTrial);
    }
  }, [search]);

  return (
    <LoginModal persistent={persistent} onClosedModal={onClose}>
      {
        () => {
          switch (currentView) {
            case Views.LOGIN:
              return (
                <AuthView
                  email={email}
                  onClickSignIn={handleOnSignIn}
                  onClickSignUp={handleOnSignUp}
                  isLogin={isLogin}
                  onForgotPassword={handleOnForgotPassword}
                />
              );
            case Views.FORGOT_PASSWORD:
              return (
                <ForgotPasswordView onSuccess={email => handleOnResetPassword(email)} />
              );
            case Views.RESET_PASSWORD:
              return (
                <EmailSentView email={resetEmail} />
              );
            default:
              return (<p />);
          }
        }
      }
    </LoginModal>
  );
};

export default withLocation(LoginComponent);

import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import KineduLogo from '../KineduLogo/KineduLogo';
import kineduLogoImage from '../../../images/landing/kinedu-logo.svg';
import aldeaLogoImage from '../../../images/landing/aldea-logo.svg'

const FooterSection = styled.footer`
  background-color: ${({ theme }) => theme?.colors?.primary};
  padding: 4rem 20px;
`;

const FooterContainer = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  ${KineduLogo} {
    img {
      width: 145px;
    }
  }

  ${({ theme }) => `
    ${theme.breakPoints.tablet} {
      flex-direction: row;
    }
  `}
`;

const Logo = styled(KineduLogo)`
  width: 177px;
  height: 22px;
`;

const Description = styled.p`
  color: #fff;
  margin: 0;
  margin-top: 10px;
  font-size: 1.2rem;
  font-weight: 300;
  ${({ theme }) => `
    ${theme.breakPoints.xLargePhone} {
      font-size: 1.4rem;
    }
  `}
`;

const OtherTradeMarks = styled.div`
  width: 320px;
  p {
    color: #fff;
    margin: 10px 0;
    font-weight: 500;
    font-size: 1.6rem;
  }
`;

const KineduLogoImg = styled.img.attrs(() => ({
  src: kineduLogoImage,
}))`
  height: 25px;
  width: fit-content;
  margin-bottom: 1rem;
`;

const AldeaLogoImg = styled.img.attrs(() => ({
  src: aldeaLogoImage,
}))`
  height: 25px;
  width: fit-content;
`;

const Footer = ({
  lang = 'en',
  children,
  innerRef,
}) => {
  const { t } = useTranslation();

  return (
    <FooterSection ref={innerRef}>
      <FooterContainer>
        <Logo variant="white" lang={lang} />
        <OtherTradeMarks>
          <p>{t('OTHER_TRADEMARKS')}</p>
          <div>
            <KineduLogoImg />
          </div>
          <div>
            <AldeaLogoImg />
          </div>
        </OtherTradeMarks>
      </FooterContainer>
    </FooterSection>
  );
};

export default Footer;

import styled from 'styled-components';
import ReactMarkdown from 'react-markdown/with-html';

export const Hero = styled.section.attrs(() => ({
  className: 'LandingHero',
}))`
  padding: 0;
  padding-top: 6rem;
  position: relative;
  overflow: hidden;
  ${({ theme }) => `
    ${theme.breakPoints.largeTablet} {
      padding: 8rem 0 8rem 0;
    }
  `}
`;

export const HeroContainer = styled.div`
  display: flex;
  flex-direction:column;
  max-width: 1060px;
  margin: 0 auto;
  .HeroImageCol {
    width: 100%;
  }
  ${({ theme }) => `
    ${theme.breakPoints.xLargePhone} {
      padding: 4rem 0;
    }
    ${theme.breakPoints.largeTablet} {
      padding: 5rem 0;
      flex-direction: row;
      max-width: 1060px;
      margin: 0 auto;
      .HeroImageCol:first-child {
        width: 45%;
      }
      .HeroImageCol:last-child {
        width: 55%;
      }
    }
  `}
`;

export const HeroImageCol = styled.div.attrs(() => ({
  className: 'HeroImageCol',
}))`
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  position: relative;
  ${({ theme }) => `
    ${theme.breakPoints.largeTablet} {
      position: static;
    }
  `}
`;

export const HeroContentCol = styled.div.attrs(() => ({
  className: 'HeroContentCol',
}))`
  width: 100%;
  box-sizing: border-box;
  position: relative;
  padding: 20px 20px;
`;

export const HeroImage = styled.img.attrs(() => ({
  className: 'HeroImage',
}))`
  width: 135px;
  transition: width 0.3s ease-in;
  ${({ theme }) => `
    ${theme.breakPoints.largePhone} {
      width: 200px;
    }

    ${theme.breakPoints.xLargePhone} {
      width: 240px;
    }

    ${theme.breakPoints.tablet} {
      width: 260px;
    }

    ${theme.breakPoints.largeTablet} {
      width: 260px;
    }

    ${theme.breakPoints.desktop} {
      width: 360px;
    }

    ${theme.breakPoints.largeDesktop} {
      width: 360px;
    }
  `}
`;

// export const HeroTitle = styled(ReactMarkdown).attrs(() => ({
export const HeroTitle = styled.h1.attrs(() => ({
  className: 'HeroTitle',
}))`
  color: #333333;
  font-weight: 400;
  line-height: 3rem;
  font-size: 2.6rem;
  margin: 0 0 1rem 0;
  transition: flex-direction 0.3s ease-in;
  > span {
    display: block;
  }
  strong {
    font-weight: 400;
    color: ${({ theme }) => `${theme?.colors?.primary}`};
  }

  div {
    strong.hidden {
      display: none;
    }
  }

  ${({ theme }) => `
    ${theme.breakPoints.xLargePhone} {
        font-size: 5.02rem;
        line-height: 5rem;
    }

    ${theme.breakPoints.tablet} {
      font-size: 5.02rem;
      line-height: 5rem;
      position: relative;
      z-index: 9;
    }

    ${theme.breakPoints.largeTablet} {
      font-size: 5.8rem;
      line-height: 5.8rem;
      margin-bottom: 6.5rem;
    }

    ${theme.breakPoints.desktop} {
      font-size: 5.8rem;
    }
  `}
`;

const colors = {
  pink: '#DA4D7A',
  amber: '#F9BA48',
  orange: '#F99848',
  green: '#44C94E',
};

const getColor = (colorName) => colors[colorName] || '#ddd';

export const Rectangle = styled.div.attrs(() => ({
  className: 'Rectangle',
}))`
  transition: all 0.5s ease-in;
  background-color: ${({ color }) => getColor(color)};
  position: ${({ position = 'absolute' }) => position};
  ${({ bottom }) => bottom ? `bottom: ${bottom}` : ''};
  ${({ top }) => top ? `top: ${top}` : ''};
  ${({ left }) => left ? `left: ${left}` : ''};
  ${({ right }) => right ? `right: ${right}` : ''};

  ${({ size = 'md' }) => size === 'md' ? `
    height: 68px;
    width: 103px;
  ` : ''}

  ${({ size = 'sm' }) => size === 'sm' ? `
    height: 9px;
    width: 34px;
  ` : ''}
`;

export const Circle = styled.div.attrs(() => ({
  className: 'Circle',
}))`
  transition: all 1s ease-in;
  border-radius: 100%;
  background-color: ${({ color }) => getColor(color)};
  position: ${({ position = 'absolute' }) => position};
  ${({ top }) => top ? `top: ${top}` : ''};
  ${({ bottom }) => bottom ? `bottom: ${bottom}` : ''};
  bottom: -600px;
  ${({ left }) => left ? `left: ${left}` : ''};
  ${({ right }) => right ? `right: ${right}` : ''};
  ${({ size = 'md' }) => size === 'md' ? `
    height: 350px;
    width: 350px;
  ` : ''}

  ${({ size = 'sm' }) => size === 'sm' ? `
    height: 220px;
    width: 220px;
  ` : ''}

  ${({ height, width, theme, bottom }) => `
    ${theme.breakPoints.largeTablet} {
      ${bottom ? `bottom: ${bottom}` : ''};
    }
    ${height ? `height:${height};` : ''}
    ${width ? `width:${width};` : ''}
  `}
`;

export const GreenCircle = styled(Circle).attrs(() => ({
  color: 'green',
}))`
  ${({ theme }) => `
    top: -110px;
    bottom: -600px;
    right: -80px;
    height: 220px;
    width: 220px;
    z-index: 8;
    ${theme.breakPoints.largeTablet} {
      height: 350px;
      width: 350px;
      right: -165px;
      top: -238px;
    }
    ${theme.breakPoints.desktop} {
      height: 430px;
      width: 430px;
      right: -230px;
      top: -312px;
    }
    ${theme.breakPoints.largeDesktop} {
      height: 630px;
      width: 630px;
      right: -260px;
      top: -360px;
    }
    ${theme.breakPoints.biggerScreens} {
      height: 900px;
      width: 900px;
      right: -270px;
      top: -520px;
    }
  `}
`;

export const HeroImageContainer = styled.div`
  position: relative;
  text-align: center;
  margin: 0 auto;
  width: fit-content;
`;

export const OrangeCircle = styled(Circle).attrs(() => ({
  color: 'orange',
}))`
  bottom: -1240px;
  height: 350px;
  width: 350px;
  left: 30%;
  height: 350px;
  width: 350px;
  transform: translateX(-30%);
  ${({ theme }) => `
    ${theme.breakPoints.largeTablet} {
      bottom: -240px;
    }
  `}
`;

export const AmberRectangle = styled(Rectangle).attrs(() => ({
  color: 'amber',
}))`
  left: -16px;
  height: 76px;
  width: 103px;
  top: 50%;
  z-index: -1;
  transform: translateY(-50%);
  ${({ theme }) => `
    ${theme.breakPoints.largeTablet} {
      width: 154px;
      height: 162px;
    }
  `}
`;

export const PinkRectangle = styled(Rectangle).attrs(() => ({
  color: 'pink',
}))`
  top: 37%;
  right: -4px;
  height: 9px;
  width: 34px;
  transform: translateY(50%);

  ${({ theme }) => `
    ${theme.breakPoints.largeTablet} {
      height: 27px;
      width: 102px;
    }
  `}
`;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TestimonialCard = styled.div.attrs(() => ({
  className: 'TestimonialCard',
}))`
  box-shadow: 0 2px 4px 0 rgba(165,165,165,0.5);
  padding: 20px;
  border-radius: 8px;
  transition: box-shadow 0.3s ease-in;
  user-select: none;
  background-color: #FFFFFF;
  &:hover {
    box-shadow: 1px 3px 12px 0px  rgba(0,0,0,0.48);
  }
`;

const TestimonialTitle = styled.h3.attrs(() => ({
  className: 'TestimonialTitle',
}))`
  ${({ theme }) => `
    color: ${theme?.colors?.primary};
  `}
  font-weight: 600;
  font-size: 1.8rem;
  text-align: center;
`;

const TestimonialComment = styled.p.attrs(() => ({
  className: 'TestimonialComment',
}))`
  color: #8B8B8B;
  font-weight: 400;
  font-size: 1.3rem;
  margin: 10px 0;
  min-height: 180px;
`;


const TestimonialCardComponent = ({
  title,
  description,
  style,
  className,
  animationDelay = '0.5s',
  animationDuration = '1s',
}) => (
  <TestimonialCard style={style} className={`wow ${className}`} data-wow-duration={animationDuration} data-wow-delay={animationDelay}>
    <TestimonialTitle>
      {title}
    </TestimonialTitle>
    <TestimonialComment>
      {description}
    </TestimonialComment>
  </TestimonialCard>
);

TestimonialCardComponent.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  animationDelay: PropTypes.string,
  animationDuration: PropTypes.string,
};

TestimonialCardComponent.defaultProps = {
  animationDelay: '0.5s',
  animationDuration: '1s',
};

export default TestimonialCardComponent;

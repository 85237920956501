import React from 'react';
import styled from 'styled-components';
import BenefitCard from 'components/Landing/BenefitCard/BenefitCard';

import schoolImgBenefit from 'images/landing/benefit_school.svg';
import familyImgBenefit from 'images/landing/benefit_family.svg';
import teacherImgBenefit from 'images/landing/benefit_teacher.svg';
import { useTranslation } from 'react-i18next';

const BenefitsSectionComponent = styled.section`
  background-color: #F4F4F4;
  color: white;
  padding: 5rem 0;
`;

const BenefitsContainer = styled.div`
  max-width: 1060px;
  margin: 0 auto;
  padding: 0 20px;
`;

const BenefitsTitle = styled.h2`
  color: ${({ theme }) => theme?.colors?.primary};
  font-size: 2.2rem;
  font-weight: bold;
  line-height: 30px;
  padding: 20px 0;
  text-align: center;
  margin: 10px 0;
  ${({ theme }) => `
    ${theme.breakPoints.tablet} {
      font-size: 3.6rem;
    }
  `}
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  ${({ theme }) => `
    ${theme.breakPoints.xLargePhone} {
      flex-direction: row;
    }
  `}
`;

const Col = styled.div`
  width: 100%;
  ${({ theme }) => `
    ${theme.breakPoints.xLargePhone} {
      flex-direction: row;
      width: 33.3%;
    }
  `}
`;

const BenefitCardContainer = styled.div`
  padding: 10px;
`;

const BenefitsSection = ({
  innerRef,
}) => {
  const { t } = useTranslation();

  return (
    <BenefitsSectionComponent ref={innerRef}>
      <BenefitsContainer>
        <BenefitsTitle>
          {t('BENEFITS.TITLE')}
        </BenefitsTitle>
        <Row>
          <Col>
            <BenefitCardContainer>
              <BenefitCard
                title={t('BENEFITS.SCHOOLS')}
                className="animate__backInUp"
                image={schoolImgBenefit}
                description={t('BENEFITS.SCHOOLS_DESCRIPTION')}
              />
            </BenefitCardContainer>
          </Col>
          <Col>
            <BenefitCardContainer>
              <BenefitCard
                title={t('BENEFITS.TEACHERS')}
                className="animate__backInUp"
                image={teacherImgBenefit}
                description={t('BENEFITS.TEACHERS_DESCRIPTION')}
              />
            </BenefitCardContainer>
          </Col>
          <Col>
            <BenefitCardContainer>
              <BenefitCard
                title={t('BENEFITS.PARENTS')}
                className="animate__backInUp"
                image={familyImgBenefit}
                description={t('BENEFITS.PARENTS_DESCRIPTION')}
              />
            </BenefitCardContainer>
          </Col>
        </Row>
      </BenefitsContainer>
    </BenefitsSectionComponent>
  );
};

export default BenefitsSection;

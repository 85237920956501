import React from 'react';
import PropTypes from 'prop-types';
import TextLoop from 'react-text-loop';
import {
  Hero,
  HeroContainer,
  HeroImageCol,
  HeroContentCol,
  HeroImageContainer,
  HeroImage,
  HeroTitle,
  GreenCircle,
  PinkRectangle,
  AmberRectangle,
  OrangeCircle,
} from './styles';
import { useTranslation } from 'react-i18next';

const LandingHeroComponent = ({
  image,
  title,
  children,
  innerRef,
}) => {
  const { t } = useTranslation();

  return (
    <Hero ref={innerRef}>
      <GreenCircle className="wow animate__zoomIn" data-wow-duration="1s" data-wow-delay="0.5s" />
      <HeroContainer>
        <HeroImageCol>
          <HeroImageContainer>
            <HeroImage src={image} />
            <PinkRectangle />
          </HeroImageContainer>
          <AmberRectangle className="wow animate__animated animate__yavas animate__pulse animate__infinite infinite" />
        </HeroImageCol>
        <HeroContentCol padded>
          <HeroTitle className="HeroTitle">
            {t('LANDING.HERO.TITLE')}
            <span>
              {t('LANDING.HERO.SUBTITLE')}
            </span>
            <TextLoop>
              <strong className="animate__animated">
                {t('LANDING.HERO.ONE')}
              </strong>
              <strong className="animate__animated">
                {t('LANDING.HERO.TWO')}
              </strong>
              <strong className="animate__animated">
                {t('LANDING.HERO.THREE')}
              </strong>
            </TextLoop>
          </HeroTitle>
          {children}
        </HeroContentCol>
        <OrangeCircle className="wow animate__animated animate__yavas animate__pulse animate__infinite infinite" />
      </HeroContainer>
    </Hero>
  );
};

LandingHeroComponent.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default LandingHeroComponent;

import React from 'react';
import styled from 'styled-components';
import LoginCTAForm from 'components/Landing/LoginCTAForm/LoginCTAForm';

const LoginCTAFormSection = styled.section`
  background-color: #fff;
  padding: 3.5rem 2rem;
`;

const Form = styled(LoginCTAForm).attrs(() => ({
  hideLinkToKinedu: true,
}))`
  .Row .Col:first-child, .Row .Col:last-child {
    width: 100%;
  }

  ${({ theme }) => `
    ${theme.breakPoints.largeTablet} {
      .Row .Col:first-child {
        width: 70%;
      }

      .Row .Col:last-child {
        width: 30%;
      }
    }

  `}
`;

const LoginCTAFormContainer = styled.div`
  padding: 0;
  max-width: 1060px;
  margin: 0 auto;
  .Col:first-child {
    width: 70%;
  }
  .Col:last-child {
    width: 30%;
  }
`;

const LoginCTAFormSectionComponent = ({
  innerRef,
}) => (
  <LoginCTAFormSection ref={innerRef}>
    <LoginCTAFormContainer>
      <Form doNotTrack />
    </LoginCTAFormContainer>
  </LoginCTAFormSection>
);

export default LoginCTAFormSectionComponent;

import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { slugify } from '../../../lib/utils';

const HighlightItemSection = styled.div.attrs(() => ({
  className: 'HighlightItemSection',
}))``;

const Col = styled.div``;

const HighlightItemContainer = styled.div.attrs(() => ({
  className: 'HighlightItemContainer',
}))`
  width: 100%;
  max-width: 1260px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;

  ${({ theme, reversed }) => `
    ${theme.breakPoints.tablet} {
      flex-direction: row;
      ${reversed ? `
        flex-direction: row-reverse;
        ` : `
        flex-direction: row;
      `}

      ${Col} {
        width: 50%;
      }
    }
  `}
`;

const Content = styled.div`
  padding: 10px;
  ${({ reversed, theme }) => `
    ${reversed ? `
      ${theme.breakPoints.tablet} {
        max-width: 80%;
        margin: auto;
        margin-right: revert;
      }
    ` : ''}
  `}

  ${({ theme }) => `
    h2 {
      margin: 1rem 0;
      color: #0093DD;
      font-weight: 600;
      font-size: 2.8rem;
      padding-right: 4rem;
      color: ${theme.colors.blue};
    }

    p {
      font-weight: 300;
      color: #8B8B8B;
      font-size: 1.3rem;
      margin: 10px 0;
    }

    ${theme.breakPoints.tablet} {
      h2 {
        font-size: 3.6rem;
      }
      p {
        font-size: 1.6rem;
        line-height: 2.6rem;
      }
    }
  `}
`;

const Image = styled.img`
  width: 100%;
  height: 360px;
  ${({ desktop, theme }) => `

    ${desktop ? `
      display: none;

      ${theme.breakPoints.tablet} {
        display: block;
      }
    ` : `
      display: block;
      ${theme.breakPoints.tablet} {
        display: none;
      }
    `}
  `}
`;

const HighlightItem = ({
  id,
  title,
  description,
  imageDesktop,
  image,
  reversed,
  className,
  delayAnimationLeftSection,
  delayAnimationRightSection,
  durationAnimationLeftSection,
  durationAnimationRightSection,
}) => {
  const internalId = useMemo(() => {
    if (!id) {
      return slugify(title);
    }

    return id;
  }, [id, title]);

  const calculateAnimation = useCallback((side) => {
    if (reversed && side === 'left') {
      return 'animate__fadeInRight';
    }

    if (!reversed && side === 'right') {
      return 'animate__fadeInRight';
    }

    if (reversed && side === 'right') {
      return 'animate__fadeInLeft';
    }

    if (!reversed && side === 'left') {
      return 'animate__fadeInLeft';
    }

    return 'animate__fadeInLeft';
  }, [reversed]);

  return (
    <HighlightItemSection id={internalId} className={className}>
      <HighlightItemContainer reversed={reversed}>
        <Col className={`wow ${calculateAnimation('left')}`} data-wow-duration={durationAnimationLeftSection} data-wow-delay={delayAnimationLeftSection}>
          <Content>
            <Image src={image} />
            { imageDesktop && <Image desktop src={imageDesktop} />}
          </Content>
        </Col>
        <Col className={`wow ${calculateAnimation('right')}`} data-wow-duration={durationAnimationRightSection} data-wow-delay={delayAnimationRightSection}>
          <Content reversed={reversed}>
            <h2>{title}</h2>
            <p>{description}</p>
          </Content>
        </Col>
      </HighlightItemContainer>
    </HighlightItemSection>
  );
};

HighlightItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  reversed: PropTypes.bool,
  imageDesktop: PropTypes.string,

  delayAnimationLeftSection: PropTypes.string,
  delayAnimationRightSection: PropTypes.string,
  durationAnimationLeftSection: PropTypes.string,
  durationAnimationRightSection: PropTypes.string,
};

HighlightItem.defaultProps = {
  reversed: false,
  imageDesktop: '',
  delayAnimationLeftSection: '0.5s',
  delayAnimationRightSection: '0.5s',
  durationAnimationLeftSection: '1s',
  durationAnimationRightSection: '1s',
};

export default HighlightItem;
